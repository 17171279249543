<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        :to="{ name: 'performance/goal' }"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        <feather-icon icon="ArrowLeftIcon" />
        <span class="bold ml-1">Back</span>
      </b-link>
    </div>

    <div class="text-center mt-3">
      <h2 style="color: #1e1e1e">Behavior</h2>
      <span class="text-muted"> Please fill the behavior form </span>
    </div>

    <b-row class="justify-content-center">
      <b-col md="6">
        <b-card class="mt-3 rounded-3">
          <form @submit.prevent>
            <b-form-group label="Behavior">
              <b-form-select
                id="behavior"
                v-model="behavior"
                :options="datas"
                size="sm"
                @input="getDesc($event)"
              />
            </b-form-group>
          </form>

          <b v-if="desc">Description:</b>
          <b-list-group flush v-if="desc">
            <b-list-group-item v-for="desc in desc" :key="desc">{{
              desc
            }}</b-list-group-item>
          </b-list-group>

          <div class="text-right">
            <b-link :to="{ name: 'performance/goal' }">
              <b-button
                variant="outline-primary"
                class="rounded-3 mr-1"
                size="sm"
              >
                Back to the form
              </b-button>
            </b-link>
            <b-button
              type="submit"
              variant="primary"
              class="rounded-3"
              size="sm"
              @click="goals_summary($event)"
            >
              Continue
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.bg-white {
  background-color: #fff;
}

.rounded-3 {
  border-radius: 1.75rem;
}
</style>

<script>
export default {
  data() {
    return {
      strategy: "",
      weight: "",
      target: "",
      due_date: "",
      kpi: "",

      datas: [],
      behavior: "",
      desc: "",
    };
  },
  mounted() {
    this.callApi({
      url: "/behaviors/fetch",
      method: "GET",
      success: (res) => {
        res.result.map((item) => {
          this.datas.push({
            value: item.id,
            text: item.name,
          });
        });
      },
    });
  },

  methods: {
    getDesc(data) {
      this.callApi({
        url: "/behaviors/fetch",
        method: "GET",
        success: (res) => {
          this.desc = [];
          res.result.map((item) => {
            if (this.behavior == item.id) {
              let items = item.description.split(",");
              items.forEach((value) => {
                this.desc.push(value.trim());
              });
            }
          });
        },
      });
    },

    goals_summary(e) {
      e.preventDefault();

      let goals = JSON.parse(localStorage.getItem("goals"));

      goals.forEach((item) => {
        if (item.other && Array.isArray(item.other)) {
          item.other.forEach((otherItem) => {
            if (otherItem.behavior && Array.isArray(otherItem.behavior)) {
              otherItem.behavior.push(this.behavior);
              if (localStorage.getItem("rtype") == "final") {
                otherItem.final_manager_behavior.push(this.behavior);
              }
            } else {
              otherItem.behavior = this.behavior;
              if (localStorage.getItem("rtype") == "final") {
                otherItem.final_manager_behavior = this.behavior;
              }
            }
          });
        } else {
          item.other = [{ behavior: this.behavior }];
          if (localStorage.getItem("rtype") == "final") {
            item.other = [{ final_manager_behavior: this.behavior }];
          }
        }
      });

      localStorage.setItem("goals", JSON.stringify(goals));

      this.$router.push("/performance/goal/overall");
    },
  },
};
</script>